import Vue from 'vue'
import VueRouter from 'vue-router'
import PcHome from '../views/pc/Home.vue'
import MbHome from '../views/mobile/Home.vue'

const isMobile = window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
const path = isMobile ? 'mobile/' : 'pc/'

const routerRePush = VueRouter.prototype.push
VueRouter.prototype.push = function (location) {
  return routerRePush.call(this, location).catch(error => error)
} //重复点击路由，导致提示避免到当前位置的冗余导航（路由冗余）解决方式

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'Home',
    component: isMobile ? MbHome : PcHome
  },
  {
    path: '/kjcx',//重大科技创新成就
    name: 'TechInnovate',
    meta: {
      keepAlive: true,
      isBack: true,
      title: '重大科技创新成就'
    },
    component: (resolve) => require([`../views/${path}TechInnovate.vue`], resolve)
  },
  {
    path: '/kjcx/:id',//详情-重大科技创新成就
    name: 'TechInnovateDetail',
    meta: {
      title: '重大科技创新成就'
    },
    component: (resolve) => require([`../views/${path}TechInnovateDetail.vue`], resolve)
  },
  {
    path: '/kjsh',//科技改变生活
    name: 'TechLife',
    meta: {
      title: '科技改变生活'
    },
    component: (resolve) => require([`../views/${path}TechLife.vue`], resolve)
  },
  {
    path: '/kjsh/:id',//详情-科技改变生活
    name: 'TechLifeDetail',
    meta: {
      title: '科技改变生活'
    },
    component: (resolve) => require([`../views/${path}TechLifeDetail.vue`], resolve)
  },
  {
    path: '/dtcy',//低碳产业发展
    name: 'LowCarbon',
    meta: {
      title: '低碳产业发展'
    },
    component: (resolve) => require([`../views/${path}LowCarbon.vue`], resolve)
  },
  {
    path: '/dtcy/:id',//详情-低碳产业发展
    name: 'LowCarbonDetail',
    meta: {
      title: '低碳产业发展'
    },
    component: (resolve) => require([`../views/${path}LowCarbonDetail.vue`], resolve)
  },
  {
    path: '/kjaq',//科技安全教育
    name: 'TechSecurity',
    meta: {
      title: '科技安全教育'
    },
    component: (resolve) => require([`../views/${path}TechSecurity.vue`], resolve)
  },
  {
    path: '/kjaq/:id',//详情-科技安全教育
    name: 'TechSecurityDetail',
    meta: {
      title: '科技安全教育'
    },
    component: (resolve) => require([`../views/${path}TechSecurityDetail.vue`], resolve)
  },
  {
    path: '/jczx',//精彩展项
    name: 'Exhibits',
    meta: {
      title: '精彩展项'
    },
    component: (resolve) => require([`../views/${path}Exhibits.vue`], resolve)
  },
  {
    path: '/jczx/:id',//详情-精彩展项
    name: 'ExhibitsDetail',
    meta: {
      title: '精彩展项'
    },
    component: (resolve) => require([`../views/${path}ExhibitsDetail.vue`], resolve)
  },
  {
    path: '/kpjt',//科普知识
    name: 'Lecture',
    meta: {
      title: '科普知识'
    },
    component: (resolve) => require([`../views/${path}Lecture.vue`], resolve)
  },

  {
    path: '/kpjt/:id',//详情-科普知识
    name: 'LectureDetail',
    meta: {
      title: '科普知识'
    },
    component: (resolve) => require([`../views/${path}LectureDetail.vue`], resolve)
  },
  {
    path: '/zcxj',//政策宣讲
    name: 'PolicyPropaganda',
    meta: {
      title: '科普讲堂',
      keepAlive:true
    },
    component: (resolve) => require([`../views/${path}PolicyPropaganda.vue`], resolve)
  },

  {
    path: '/zcxj/:id',//详情-政策宣讲
    name: 'PolicyPropagandaDetail',
    meta: {
      title: '政策宣讲'
    },
    component: (resolve) => require([`../views/${path}PolicyPropagandaDetail.vue`], resolve)
  },
  {
    path: '/news',//新闻中心
    name: 'News',
    meta: {
      title: '新闻中心'
    },
    component: (resolve) => require([`../views/${path}News.vue`], resolve)
  },
  {
    path: '/news/:id',//详情-新闻中心
    name: 'NewsDetail',
    meta: {
      title: '新闻中心'
    },
    component: (resolve) => require([`../views/${path}NewsDetail.vue`], resolve)
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
