import { request } from "../utils/request";

//新闻中心列表-首页
export function newsListindex(data) {
  return request({
    url: "/news/getNewsCenterHomePage",
    method: "post",
    data
  });
}

//新闻中心列表轮播-首页
export function newsLbListindex(data) {
  return request({
    url: "/news/getNewsCenterHomeTop",
    method: "post",
    data
  });
}


//精彩展项-首页
export function jczxListindex(data) {
  return request({
    url: "/wonderfulElements/getWonderfulElementsHomePage",
    method: "post",
    data
  });
}


//科普讲堂-首页
export function kpjtListindex(data) {
  return request({
    url: "/popularizationOfScienceClassroom/getPopularizationOfScienceHomePage ",
    method: "post",
    data
  });
}

//科普讲堂轮播-首页
export function kpjtLbListindex(data) {
  return request({
    url: "/popularizationOfScienceClassroom/getPopularizationOfScienceHomePageTop",
    method: "post",
    data
  });
}




//新闻中心列表
export function newsList(data) {
  return request({
    url: "/news/getNewsCenterByPage",
    method: "post",
    data
  });
}



//新闻中心详情
export function newsListDetails(data) {
  return request({
    url: "/news/getNewsCenterDetails",
    method: "post",
    data
  });
}



//科普讲堂
export function kpjtList(data) {
  return request({
    url: "/popularizationOfScienceClassroom/getList",
    method: "post",
    data
  });
}

//科普讲堂详情
export function kpjtListDetails(data) {
  return request({
    url: "/popularizationOfScienceClassroom/getPopularizationOfScienceClassroom",
    method: "post",
    data
  });
}


//精彩展项
export function jczxList(data) {
  return request({
    url: "/wonderfulElements/getList",
    method: "post",
    data
  });
}

//精彩展项详情
export function jczxListDetails(data) {
  return request({
    url: "/wonderfulElements/getWonderfulElements",
    method: "post",
    data
  });
}


//云科普展厅
export function ykpzdList(data) {
  return request({
    url: "/exhibitionHall/getList",
    method: "post",
    data
  });
}
//云科普展厅详情
export function ykpzdListDetails(data) {
  return request({
    url: "/exhibitionHall/getExhibitionHall",
    method: "post",
    data
  });
}