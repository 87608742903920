<template>
  <div class="wrapper">
    <!-- 活动标题 -->
    <svg-icon icon-class="title" class-name="logo" />
    <!-- 启动式 -->
    <div class="start">
      <div class="title">
        <strong>启动式</strong>
        <!-- <span>5月21日(周六)</span>
        <span>上午10:00</span>
        <span>准时开播</span> -->
      </div>
      <div class="flex-wrapper">
        <p>
          科技活动周是中国政府于2001年批准设立的大规模群众性科学技术活动。
          中国科协等19个部门和单位组成科技活动周组委会，同期在全国范围内组织实施。
        </p>
        <!-- <iframe src="https://player.bilibili.com/player.html?aid=769326184&bvid=BV12r4y147Qt&cid=725451725&page=1" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe> -->
        <!-- <button @click="goLive"><span>观看直播</span><svg-icon icon-class="arrow-line" /></button> -->
        <!-- <video
                width="100%"
                controls="controls"
                autoplay="autoplay"
                poster="/1.png"
              >
                <source
                  src="http://kjhdz.jlstis.com/file/pdf/jkgc.mp4"
                  type="video/mp4"
                />
              </video> -->
        <div class="player">
          <video-player
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            x5-video-player-type="h5"
            :playsinline="true"
            :options="playerOptions"
          >
          </video-player>
        </div>
      </div>
    </div>
    <!-- 云展厅&云展项&科普讲堂 -->
    <section class="flex-wrapper">
      <div class="left">
        <h3>云科普展厅</h3>
        <span>Cloud Science Exhibition Hall</span>
        <ul>
          <router-link to="/kjcx" tag="li">
            <div>
              <span>重大科技创新成就</span>
              <div>
                <svg-icon icon-class="arrow-line" />
              </div>
            </div>
          </router-link>
          <router-link to="/dtcy" tag="li">
            <div>
              <span>低碳产业发展</span>
              <div><svg-icon icon-class="arrow-line" /></div>
            </div>
          </router-link>
          <router-link to="/kjsh" tag="li">
            <div>
              <span>科技改变生活</span>
              <div><svg-icon icon-class="arrow-line" /></div>
            </div>
          </router-link>
          <router-link to="/kjaq" tag="li">
            <div>
              <span>科技安全教育</span>
              <div>
                <svg-icon icon-class="arrow-line" />
              </div>
            </div>
          </router-link>
        </ul>
      </div>
      <div class="right">
        <router-link class="item" tag="div" to="/jczx">
          <div>
            <h3>精彩展项</h3>
            <span>Exhibition Item</span>
          </div>
          <svg-icon icon-class="arrow-blue" />
        </router-link>
        <router-link class="speech" tag="div" to="/zcxj">
          <div>
            <h3>科普讲堂</h3>
            <span>Lecture Hall</span>
          </div>
          <svg-icon icon-class="arrow-blue" />
        </router-link>
      </div>
    </section>
    <!-- 新闻中心 -->
    <div class="news">
      <router-link class="flex-wrapper top" to="/news" tag="div">
        <h3>新闻中心<span>News Center</span></h3>
        <svg-icon icon-class="arrow-blue"></svg-icon>
      </router-link>
      <div class="flex-wrapper">
        <ul>
          <li v-for="item in news" :key="item.jid">
            <router-link :to="`/news/${item.jid}`">{{
              item.theme
            }}</router-link>
          </li>
        </ul>
        <div class="graphic"></div>
      </div>
    </div>
    <!-- 大赛 -->
    <div class="competition">
      <div>
        <h3>吉林省科普讲解大赛</h3>
        <span>Science Knowledge Explanation Competition</span>
      </div>
    </div>
    <div class="competition">
      <div class="video">
        <h3>吉林省科普微视频大赛</h3>
        <span>Science Popularization Micro Video Competition</span>
      </div>
    </div>
    <!-- 知识阅读服务平台 -->
    <div class="cnki">
      <div>
        <a href="https://shuwu.cnki.net/wxsw/jckp_index">
          <h3>科普阅读知识服务平台</h3>
          <span>Science Reading Knowledge Service Platform</span>
        </a>
      </div>
    </div>
    <footer>吉ICP备07890000号-2</footer>
  </div>
</template>

<script>
import { getNewsList } from '@/api/mobile.js';
import { videoPlayer } from 'vue-video-player';
export default {
  name: 'MbHome',
  data() {
    return {
      news: [],
      playerOptions: {
        //        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4',
            src: 'http://kjhdz.jlstis.com/file/pdf/jkgc.mp4', //你的视频地址（必填）
          },
        ],
        poster: '/1.png', //你的封面地址
        width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
          remainingTimeDisplay: false,
          fullscreenToggle: true  //全屏按钮
        }
      },
    };
  },
  components: {
    videoPlayer,
  },
  created() {
    this.getNews();
  },
  beforeRouteLeave(to, from, next) {
    to.meta.isBack = false;
    next();
  },
  methods: {
    getNews() {
      let params = {
        page: 1,
        pageSize: 2,
      };
      getNewsList(params).then((res) => {
        this.news = res.data.list;
      });
    },
    goLive() {
      window.open('https://k.cnki.net/CInfo/Index/18453', '_blank');
    },
    onPlayerPlay(player) {
      alert('play');
    },
    onPlayerPause(player) {
      alert('pause');
    },
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100vw;
  padding: 20px 30px 0px 30px;
}
.wrapper::before {
  content: ' ';
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url('../../assets/img/bg.jpg') top center no-repeat;
  background-size: cover;
}
.logo {
  width: 92vw;
  height: 36vw;
}
.flex-wrapper {
  display: flex;
  justify-content: space-between;
}
.start {
  position: relative;
  // height: 290px;
  // height: 460px;
  background: url('../../assets/img/bg-start.png') no-repeat;
  background-size: cover;
  border-radius: 20px;
  padding: 80px 30px 30px 30px;
}
.start .title {
  background-color: #0a82ea;
  color: #fff;
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  line-height: 56px;
  border-bottom-left-radius: 12px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
}
.start .title strong {
  font-size: 32px;
}
.start .title span {
  font-size: 28px;
}
.start p {
  color: #fff;
  flex: 1.4;
  font-size: 22px;
  text-indent: 48px;
}
.start .player,.start video,.start iframe{
  width: 320px;
  height: 170px;
  margin-left: 10px;
}
.start button {
  margin-top: 50px;
  margin-left: 30px;
  height: 50px;
  background: url('../../assets/img/bg-btn.png') repeat-x;
  color: #fff;
  flex: 1;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.start button span {
  font-size: 26px;
  margin-left: 56px;
  margin-right: 8px;
}
.start button svg {
  height: 26px;
  width: 26px;
  // padding-top: 10px;
}
section {
  margin-top: 24px;
}
.left {
  width: 330px;
  height: 402px;
  background: url('../../assets/img/bg-cloud.png') no-repeat;
  background-size: cover;
  padding: 14px;
  border-radius: 20px;
}
.left h3 {
  font-size: 32px;
  color: #fff;
  line-height: 1;
  margin-top: 10px;
}
.left > span {
  font-size: 18px;
  color: #fff;
  margin-bottom: 10px;
  display: block;
}
.left ul {
  width: 100%;
  height: 300px;
  background-color: rgba(37, 16, 92, 0.9);
  padding: 5px 10px;
  border-radius: 15px;
}
.left ul li {
  border: 1px solid #0a82ea;
  height: 58px;
  margin: 12px 0;
  border-radius: 10px;
  padding: 0 10px;
  overflow: hidden;
}
.left ul li div {
  display: flex;
  height: 58px;
  justify-content: space-between;
  align-items: center;
}
.left ul li div span {
  font-size: 24px;
  display: block;
  line-height: 58px;
  color: #fff;
}
.left ul li div svg {
  width: 24px;
}
.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.right > div {
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 10px 30px;
  border-radius: 20px;
  position: relative;
}
.item {
  width: 330px;
  height: 193px;
  background: url('../../assets/img/bg-item.png') no-repeat;
}
.right > div::after {
  display: block;
  content: '';
  position: absolute;
  height: 1px;
  left: 30px;
  right: 30px;
  background-color: #fff;
  bottom: 50px;
}
.item h3,
.speech h3 {
  font-size: 32px;
  line-height: 1;
  margin-top: -40px;
}
.item span,
.speech span {
  font-size: 18px;
}
.item svg,
.speech svg {
  width: 40px;
  margin-top: -40px;
}
.speech {
  width: 330px;
  height: 193px;
  background: url('../../assets/img/bg-microphone.png') no-repeat;
}
.news {
  background-color: #25105c;
  border-radius: 20px;
  margin-top: 24px;
  padding: 25px 30px;
  height: 244px;
}
.news .top {
  color: #fff;
}
.news .top h3 {
  font-size: 32px;
}
.news .top h3 span {
  font-size: 18px;
  font-weight: normal;
  margin-left: 10px;
}
.news svg {
  width: 40px;
  height: 40px;
}
.news ul {
  margin-top: 10px;
}
.news ul li:first-child {
  border-bottom: 1px solid #fff;
}
.news ul a {
  color: #fff;
  font-size: 24px;
  display: block;
  width: 380px;
  padding: 15px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.news .graphic {
  margin-top: 10px;
  width: 150px;
  height: 133px;
  background: url('../../assets/img/graphic-news.png') no-repeat center center;
  background-size: contain;
}
.competition,
.cnki {
  width: 100%;
  height: 184px;
  margin-top: 24px;
  background-color: #334bde;
  border-radius: 20px;
  padding: 25px 30px;
  color: #fff;
}
.cnki {
  background-color: #300287;
}
.competition > div {
  padding-left: 260px;
  background: url('../../assets/img/graphic-three.png') no-repeat left center;
  background-size: contain;
  height: 134px;
}
.competition > div.video {
  padding-left: 240px;
  background: url('../../assets/img/graphic-video.png') no-repeat left center;
  background-size: contain;
  height: 134px;
}
.cnki > div {
  padding-right: 260px;
  background: url('../../assets/img/graphic-cnki.png') no-repeat right center;
  background-size: contain;
  height: 134px;
}
.cnki a h3,
.cnki a span {
  color: #fff;
}
.competition h3,
.cnki h3 {
  font-size: 32px;
  line-height: 1;
  padding-top: 30px;
  margin-bottom: 10px;
}
.competition span,
.cnki span {
  font-size: 18px;
  display: inline-block;
  padding-top: 8px;
  border-top: 1px solid #fff;
}
footer {
  background-color: #3f49c9;
  font-size: 20px;
  text-align: center;
  padding: 30px;
  color: #fff;
  margin: 24px -30px 0px -30px;
}
::v-deep .vjs-custom-skin > .video-js{
  width: 320px;
}
::v-deep .video-js .vjs-control-bar{
  width: 320px;
}
::v-deep .vjs-custom-skin > .video-js .vjs-big-play-button {
  width: 1.6em;
  height: 1.6em !important;
  line-height: 1.6em !important;
  font-size: 3em;
  border-radius: 0.8em;
  margin-left: 0;
  margin-top: 0 !important;
  transform: translate(-50%, -50%);
}
// .player{
//   border-radius: 20px;
//   border:5px solid #0a82ea;
//   overflow: hidden;
// }
::v-deep .vjs-custom-skin > .video-js .vjs-progress-control.vjs-control{
  display: none !important;
}
</style>
