<template>
  <div>
    <div class="banner">
      <div class="txt">
        <h5>2022年吉林省科技活动周</h5>
        <h1>走进科技 你我同行</h1>
        <h6>2022年5月21 - 28日</h6>
      </div>
      <!-- <div class="start" @click="See('https://k.cnki.net/CInfo/Index/18453')" > 
          <p class="time">5月21日 10:00</p>
          <h4>启动式直播</h4>
        </div> -->
      <!-- <div class="player">
          <iframe src="https://player.bilibili.com/player.html?aid=769326184&bvid=BV12r4y147Qt&cid=725451725&page=1" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
        </div> -->
      <div class="player">
        <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          x5-video-player-type="h5"
          :playsinline="true"
          :options="playerOptions"
        >
        </video-player>
      </div>
    </div>
  </div>
</template>
<script>
import { videoPlayer } from 'vue-video-player';
export default {
  name: 'Banner',
  data() {
    return {
      playerOptions: {
        //        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4',
            src: 'http://kjhdz.jlstis.com/file/pdf/jkgc.mp4', //你的视频地址（必填）
          },
        ],
        poster: '/1.png', //你的封面地址
        width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
    };
  },
  components: {
    videoPlayer,
  },
  methods: {
    See(e) {
      //跳转外部链接
      // window.location.href = e;
      window.open(e, '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
.banner {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 126px auto 0;
  height: 340px;
  .txt {
    h5 {
      font-size: 40px;
      color: #fff;
      margin-top: 70px;
    }
    h1 {
      font-size: 70px;
      color: #fff;
    }
    h6 {
      font-size: 32px;
      color: #00fcff;
      margin-top: 10px;
      font-weight: normal;
      margin-bottom: 30px;
    }
  }
  .start {
    width: 416px;
    height: 124px;
    margin-top: 140px;
    background: url('~@/assets/pcimg/zb.png') no-repeat;
    cursor: pointer;
    .time {
      font-size: 20px;
      color: #fff;
      margin-top: 15px;
      padding-left: 95px;
    }
    h4 {
      font-size: 50px;
      color: #fff;
      padding-left: 40px;
    }
  }
}
// .player iframe{
//   width: 436px;
//   height: 280px;
//   margin-top: 60px;
//   border:15px solid #fff;
// }
.vjs-custom-skin {
  width: 450px;
  height: auto;
  margin-top: 40px;
  border: 15px solid #fff;
}
::v-deep .vjs-custom-skin > .video-js .vjs-big-play-button {
  width: 2em;
  height: 2em !important;
  line-height: 2em !important;
  font-size: 3.5em;
  border-radius: 1em;
  margin-left: 0;
  margin-top: 0 !important;
  transform: translate(-50%, -50%);
}
</style>
