import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VideoPlayer from 'vue-video-player'

require('video.js/dist/video-js.css')

require('vue-video-player/src/custom-theme.css')

Vue.use(VideoPlayer)

Vue.use(ElementUI);
// 引入全局样式表
import './assets/css/reset.css'
// 引入svg
import '@/icons'
//引入permission
import './permission'
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


