<template>
  <div class="home">
    <Nav></Nav>
    <div class="main">
      <banner></banner>
      <div class="column">
        <div class="title">
          <div>
            <h4>云科普展厅</h4>
            <div class="subtitle">CLOUD SCIENCE EXHIBITION HALL</div>
          </div>
        </div>
        <div class="box">
          <router-link to="/kjcx">
            <div class="item-zd">
              <h4>重大科技创新成就</h4>
              <div class="subtitle">INNOVATION ACHIEVEMENTS</div>
            </div>
          </router-link>
          <router-link to="/dtcy">
            <div class="item-dt">
              <h4>低碳产业发展</h4>
              <div class="subtitle">LOW CARBON INDUSTRY</div>
            </div>
          </router-link>
          <router-link to="/kjsh">
            <div class="item-kj">
              <h4>科技改变生活</h4>
              <div class="subtitle">TECHNOLOGY CHANGES LIFE</div>
            </div>
          </router-link>
          <router-link to="/kjaq">
            <div class="item-kjaq">
              <h4>科技安全教育</h4>
              <div class="subtitle">SAFETY EDUCATION</div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="column">
        <div class="title">
          <div>
            <h4>精彩展项</h4>
            <div class="subtitle">WONDERFUL EXHIBITION ITEMS</div>
          </div>
        </div>
        <div class="box2">
          <div class="item" @click="gotoJczx(id1)">
            <img :src="jczx" class="dim" />
            <p>{{ theme }}</p>
          </div>
          <div class="item" @click="gotoJczx(id2)">
            <img :src="jczx1" class="dim" />
            <p>{{ theme1 }}</p>
          </div>
          <div class="item2">
            <img :src="jczx2" class="dim" @click="gotoJczx(id3)" />
            <p>{{ theme2 }}</p>
            <router-link to="/jczx">
              <div class="more">更多></div>
            </router-link>
          </div>
          <div class="item" @click="gotoJczx(id4)">
            <img :src="jczx3" class="dim" />
            <p>{{ theme3 }}</p>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="title">
          <div>
            <h4>科普讲堂</h4>
            <div class="subtitle">POPULAR SCIENCE LECTURE HALL</div>
          </div>
        </div>
        <div class="box3">
          <div class="left">
            <div class="block">
              <el-carousel height="410px" :interval="3000">
                <el-carousel-item
                  v-for="(item, index) in kpjtLbtlist"
                  :key="index"
                >
                  <div class="txt">
                    <p>{{ item.theme }}</p>
                  </div>
                  <img
                    :src="item.pictureVideo"
                    @click="gotoKpjc(item.id, item.site)"
                  />
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <div class="right">
            <div class="list" @click="gotokpzs()">
              <h3>科普知识</h3>
              <span>POPULAR SCIENCE KNOWLEDGE</span>
              <div class="goto">立即前往</div>
            </div>
            <div class="list" @click="gotozcxj()">
              <h3>政策宣讲</h3>
              <span>POLICY PROPAGANDA</span>
              <div class="goto">立即前往</div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="title">
          <div>
            <h4>新闻中心</h4>
            <div class="subtitle">NEWS CENTER</div>
          </div>
          <router-link to="/news">
            <div class="more">更多></div>
          </router-link>
        </div>
        <div class="box4">
          <div class="left">
            <div class="news">
              <div class="newsimg">
                <el-carousel height="230px" :interval="3000">
                  <el-carousel-item
                    v-for="(item, index) in lblist"
                    :key="index"
                  >
                    <div class="txt1">
                      <span>{{ item.theme }}</span>
                    </div>
                    <img :src="item.photo" @click="gotonewsdetails(item.jid)" />
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="news-list">
                <ul>
                  <li
                    v-for="(item, index) in list"
                    :key="index"
                    @click="gotonewsdetails(item.jid)"
                  >
                    <div class="liftleft">{{ item.theme }}</div>

                    <span>{{item.uploadTime}}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- <div class="right">
            <div class="news-video">
              <video
                width="325"
                height="200"
                controls="controls"
                autoplay="autoplay"
              >
                <source
                  src="../../assets/pcimg/全椒农场738 757--.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <p>吉林省规上医药健康企业实现“应复尽复” 力保产</p>
          </div> -->
        </div>
      </div>
      <div class="menu">
        <div class="item">
          <p>
            吉林省
            <br />科普讲解大赛
          </p>
        </div>
        <div class="item">
          <p>
            吉林省科普
            <br />微视频大赛
          </p>
        </div>

        <div
          class="item"
          @click="See('https://shuwu.cnki.net/wxsw/jckp_index')"
        >
          <p>
            科普阅读
            <br />知识服务平台
          </p>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/views/pc/components/Footer";
import Nav from "@/views/pc/components/Nav";
import Banner from "@/views/pc/components/Banner";
import {
  newsListindex,
  // kpjtListindex,
  newsLbListindex,
  jczxListindex,
  kpjtLbListindex,
} from "@/api/pc.js";

export default {
  name: "Home",
  data() {
    return {
      list: [],
      // kpjtlist: [],
      lblist: [],
      jczx: "",
      jczx1: "",
      jczx2: "",
      jczx3: "",
      theme: "",
      theme1: "",
      theme2: "",
      theme3: "",
      kpjtLbtlist: [],
      id1: "",
      id2: "",
      id3: "",
      id4: "",
    };
  },
  components: { Footer, Nav, Banner },
  created() {
    this.newsList();
    // this.kpjtList();
    this.newsLbList();
    this.jczxList();
    this.kpjtLbList();
  },
  methods: {
    See(e) {
      //跳转外部链接
      // window.location.href = e;
      window.open(e, "_blank");
    },
    newsList() {
      newsListindex().then((res) => {
        this.list = res.data;
      });
    },
    newsLbList() {
      newsLbListindex().then((res) => {
        this.lblist = res.data;
      });
    },
    // kpjtList() {
    //   kpjtListindex().then((res) => {
    //     this.kpjtlist = res.data.list;
    //   });
    // },

    kpjtLbList() {
      kpjtLbListindex().then((res) => {
        this.kpjtLbtlist = res.data;
      });
    },

    jczxList() {
      jczxListindex().then((res) => {
        this.jczx = res.data.list[0].pictureVideo;
        this.jczx1 = res.data.list[1].pictureVideo;
        this.jczx2 = res.data.list[2].pictureVideo;
        this.jczx3 = res.data.list[3].pictureVideo;
        this.theme = res.data.list[0].theme;
        this.theme1 = res.data.list[1].theme;
        this.theme2 = res.data.list[2].theme;
        this.theme3 = res.data.list[3].theme;
        this.id1 = res.data.list[0].id;
        this.id2 = res.data.list[1].id;
        this.id3 = res.data.list[2].id;
        this.id4 = res.data.list[3].id;
      });
    },
    gotonewsdetails(id) {
      console.log(id);
      this.$router.push(`/news/${id}`);
    },
    gotoKpjc(id, site) {
      if (site != null) {
        window.open(site, "_blank");
      } else {
        this.$router.push(`/kpjt/${id}`);
      }
      //  this.$router.push(`/kpjt/${id}`);
    },
    gotoJczx(id) {
      this.$router.push(`/jczx/${id}`);
    },
    gotokpzs() {
      this.$router.push(`/kpjt/`);
    },
    gotozcxj() {
      this.$router.push(`/zcxj/`);
    },
  },
};
</script>
<style scoped>
/deep/ .el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.left /deep/ .el-carousel__indicators--horizontal {
  left: 92%;
  transform: translateX(0%);
}
.left /deep/ .el-carousel__indicator--horizontal {
  padding: 14px 4px;
}
.newsimg /deep/ .el-carousel__indicators--horizontal {
  left: 86%;
  transform: translateX(0%);
}
.newsimg /deep/ .el-carousel__indicator--horizontal {
  padding: 10px 4px;
}
.left /deep/.el-carousel__item img {
  cursor: pointer;

  width: 100%;
  height: 100%;
  object-fit: cover;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  background: url("../../assets/pcimg/bg1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  .main {
    width: 1200px;
    padding-top: 24px;
    margin: 0px auto;
    .column {
      .title {
        margin-left: 10px;
        margin-top: 40px;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        div {
          h4 {
            font-size: 32px;
            color: #fff;
          }
          .subtitle {
            font-size: 18px;
            color: #dadada;
            margin-top: 5px;
          }
        }
      }
      .more {
        font-size: 32px;
        color: #fff;
      }

      .box {
        background: #fff;
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        .item-zd {
          background: url("../../assets/pcimg/img1.png") no-repeat;
          width: 274px;
          height: 124px;
          margin-left: 20px;

          h4 {
            font-size: 24px;
            color: #fff;
            text-align: center;
            padding-top: 20px;
          }
          .subtitle {
            font-size: 14px;
            color: #dadada;
            text-align: center;
            padding-top: 5px;
          }
        }
        .item-dt {
          background: url("../../assets/pcimg/img2.png") no-repeat;
          width: 274px;
          height: 124px;
          margin-left: 20px;

          h4 {
            font-size: 24px;
            color: #fff;
            text-align: center;
            padding-top: 20px;
          }
          .subtitle {
            font-size: 14px;
            color: #dadada;
            text-align: center;
            padding-top: 5px;
          }
        }
        .item-kj {
          background: url("../../assets/pcimg/img3.png") no-repeat;
          width: 274px;
          height: 124px;
          margin-left: 20px;

          h4 {
            font-size: 24px;
            color: #fff;
            text-align: center;
            padding-top: 20px;
          }
          .subtitle {
            font-size: 14px;
            color: #dadada;
            text-align: center;
            padding-top: 5px;
          }
        }
        .item-kjaq {
          background: url("../../assets/pcimg/img4.png") no-repeat;
          width: 274px;
          height: 124px;
          margin-left: 20px;

          h4 {
            font-size: 24px;
            color: #fff;
            text-align: center;
            padding-top: 20px;
          }
          .subtitle {
            font-size: 14px;
            color: #dadada;
            text-align: center;
            padding-top: 5px;
          }
        }
      }
      .box2 {
        padding-top: 30px;
        padding-bottom: 30px;
        background: #fff;
        display: flex;
        justify-content: space-evenly;
        .item {
          background: #000;
          position: relative;
          width: 268px;
          height: 412px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          p {
            font-size: 24px;
            color: #fff;
            position: absolute;
            z-index: 2;
            bottom: 30px;
            left: 20px;
            width: 230px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 43px;

            &::before {
              content: "";
              position: absolute;
              width: 70px;
              height: 4px;
              background: #00d2ff;
              border-radius: 20px;
              bottom: 0px;
            }
          }

          .dim {
            opacity: 0.6;
            filter: alpha(opacity=60);
            transition: 1s;
            cursor: pointer;
            &:hover {
              opacity: 1;
            }
          }
        }
        .item2 {
          background: #000;
          position: relative;
          width: 268px;
          height: 264px;
          .more {
            width: 268px;
            height: 128px;
            background: #0a2142;
            text-align: center;
            line-height: 128px;
            font-size: 24px;
            color: #fff;
            margin-top: 17px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          p {
            font-size: 24px;
            color: #fff;
            position: absolute;
            z-index: 2;
            bottom: 30px;
            left: 25px;
            width: 230px;
            height: 43px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &::before {
              content: "";
              position: absolute;
              width: 70px;
              height: 4px;
              background: #00d2ff;
              border-radius: 20px;
              bottom: 0;
            }
          }

          .dim {
            opacity: 0.6;
            filter: alpha(opacity=60);
            transition: 1s;
            cursor: pointer;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
      .box3 {
        background: #fff;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        .left {
          width: 744px;
          height: 410px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .txt {
            width: 744px;
            height: 40px;
            background: #067fd8;
            color: #fff;
            font-size: 16px;
            line-height: 40px;
            padding-left: 10px;
            bottom: 0;
            position: absolute;

            p {
              width: 450px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .right {
          .list {
            background: url("../../assets/pcimg/list.png") no-repeat;
            width: 372px;
            height: 199px;
            position: relative;
            margin-top: 14px;
            cursor: pointer;
            h3 {
              font-size: 24px;
              padding-left: 30px;
              color: #fff;
              padding-top: 45px;
            }
            .goto {
              padding: 5px 15px;

              border-radius: 5px;
              width: 100px;
              margin-left: 30px;
              margin-top: 20px;
              color: #fff;
              text-align: center;
              background: linear-gradient(to right, #324bc7, #b417d0);
              cursor: pointer;
            }

            span {
              font-size: 18px;
              color: #fff;
              padding-left: 30px;
              padding-top: 10px;
              display: block;
            }
            .line {
              position: absolute;
              margin-left: 1px;
              width: 371px;
              height: 40px;
              background: #067fd8;
              font-size: 20px;
              color: #fff;
              padding-left: 30px;
              line-height: 40px;
              bottom: 0;
              display: flex;
              justify-content: space-between;
              .linetxt {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 140px;
              }
              .author {
                font-size: 16px;
                padding-right: 30px;
              }
            }
            p {
              position: absolute;
              width: 100%;
              height: 40px;
              background: #067fd8;
              font-size: 20px;
              color: #fff;
              padding-left: 30px;
              line-height: 40px;
              bottom: 0;
            }
            &:first-child {
              margin-top: 0px;
            }
          }
        }
      }
      .box4 {
        background: #fff;
        padding: 30px;
        display: flex;

        .left {
          width: 100%;
          .news-title {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 30px;
          }
          .news {
            display: flex;

            .newsimg {
              width: 434px;
              height: 231px;
              .txt1 {
                width: 100%;
                height: 30px;
                background: #067fd8;
                color: #fff;
                font-size: 16px;
                line-height: 30px;
                padding-left: 10px;
                bottom: 0;
                position: absolute;
                span {
                  width: 260px;
                  font-size: 14px;
                  color: #fff;
                  display: block;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
            .news-list {
              height: 231px;
             
              margin-left: 20px;

              ul {
                li {
                  width: 686px;
                  text-align: left;
                  font-size: 14px;
                  line-height: 33px;
                  color: #333;
                  white-space: nowrap;
                  cursor: pointer;
                  justify-content: space-between;
                  display: flex;
                  span{
                    width: 80px;
                    overflow: hidden;
                  }

                  .liftleft {
                    justify-content: space-between;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 500px;
                    &:before {
                      content: "·";
                      margin-right: 6px;
                    }
                  }
                }
              }
            }
          }
        }
        .right {
          .news-video {
            width: 325px;
            height: 190px;
          }
          .news-title {
            font-size: 20px;
            font-weight: bold;
            text-align: left;
            margin-bottom: 30px;
          }
        }
        p {
          font-size: 14px;
          line-height: 33px;
          color: #333;
          margin-top: 10px;
          &:before {
            content: "·";
            margin-right: 6px;
          }
        }
      }
    }
    .menu {
      width: 1200px;
      height: 233px;

      background-color: rgba(68, 68, 68, 0.9);
      margin-top: 70px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .item {
        width: 334px;
        height: 119px;
        cursor: pointer;
        background: url("../../assets/pcimg/dimg1.png") no-repeat;
        p {
          font-size: 30px;
          color: #fff;
          padding-top: 15px;
          padding-left: 35px;
        }
      }
    }
  }
}
</style>