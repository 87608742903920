<template>
  <header>
    <div class="puliwidth">
      <div class="logo">
        <img src="@/assets/pcimg/logo.png" />

        <div class="logotxt">
          <div class="blue">2022年</div>
          <div class="sub">吉林省科技活动周</div>
        </div>
      </div>
      <div class="nav">
        <el-menu
          :default-active="activeIndex"
          active-text-color="#095bde"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          :router="true"
          text-color="#333"
        >
          <el-menu-item index="/">首页</el-menu-item>

          <el-submenu index="2">
            <template slot="title">云科普展厅</template>
            <el-menu-item index="/kjcx">重大科技创新成就</el-menu-item>
            <el-menu-item index="/dtcy">低碳产业发展</el-menu-item>
            <el-menu-item index="/kjsh">科技改变生活</el-menu-item>
            <el-menu-item index="/kjaq">科技安全教育</el-menu-item>
          </el-submenu>
          <el-menu-item index="/jczx">精彩展项</el-menu-item>
          <el-submenu index="3">
                <template slot="title">科普讲堂</template>
            <el-menu-item index="/kpjt">科普知识</el-menu-item>
            <el-menu-item index="/zcxj">政策宣讲</el-menu-item>
          </el-submenu>
          <el-menu-item index="/news">新闻中心</el-menu-item>
          <el-menu-item @click="See('https://shuwu.cnki.net/wxsw/jckp_index')"
            >科普阅读知识服务平台</el-menu-item
          >
        </el-menu>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: "Nav",
  data() {
    return {
      activeIndex: "/",
      // activeIndex: "/" + location.hash.split("/")[1],
    };
  },
  watch: {
    //监听路由
    // "$route.path"(newVal, oldVal) {
    //   if (newVal !== oldVal) {
    //     this.activeIndex = "/" + location.hash.split("/")[1];
    //   }
    // },
  },
  methods: {
    setNavIndex(val) {
      this.activeIndex = val;
    },
    changeClass(i) {
      this.isActive = i;
    },
    btn(index) {
      this.one = index;
    },
    See(e) {
      //跳转外部链接
      // window.location.href = e;
         window.open(e,"_blank")
    },

    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },

    // gotokjcx() {
    //   this.$router.push({
    //     path: "/kjcx"
    //     // query:{
    //     // 	id:this.id
    //     // }
    //   });
    // },
  },
};
</script>
<style scoped>

.el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title{
  text-align: center !important;
}
/deep/ .el-submenu__title {
  font-size: 18px;
  color: #333;
}
/deep/ .el-menu--horizontal > .el-submenu .el-submenu__title {
  color: #333;
}
/* /deep/ .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  color: #095bde;
  border: none;
} */
/deep/ .el-icon-arrow-down:before {
  font-size: 16px;
  color: #333;
}
/deep/ .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border: none;
}
.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  color: #333;
  font-size: 18px;
  height: 45px;
  line-height: 45px;
}
/deep/ .el-menu--horizontal > .el-submenu .el-submenu__title {
  border: none;
}
</style>
<style lang="scss" scoped>
header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  width: 100%;

  background-color: #fff;
  .puliwidth {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .logo {
      display: flex;
      .logotxt {
        height: 63px;
        border-left: #bcbcbc solid 1px;
        margin-left: 11px;
        margin-top: 30px;
        font-size: 28px;
        .blue {
          color: #063dd7;
          font-weight: bold;
          padding-left: 11px;
          margin-top: -9px;
        }
        .sub {
          font-weight: bold;
          padding-left: 11px;
          color: #333;
        }
      }
    }

    .nav {
      margin-top: 30px;
      .el-menu-item {
        font-size: 18px;
        color: #333;
        padding: 0 18px;
      }

      .el-menu.el-menu--horizontal {
        border: none;
      }
      .el-menu--horizontal > .el-menu-item.is-active {
        border: none;
        color: #095bde;
      }
      .el-menu--horizontal > .el-menu-item {
        border: none;
      }
    }
  }
}
</style>