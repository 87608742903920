import axios from 'axios'
export function request(config) {
  // 1.创建axios的实例
  const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, 
    timeout: 50000,
  })
  instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
  instance.defaults.transformRequest = 
    [function (data) {  // 将{username:111,password:111} 转成 username=111&password=111
      var ret = '';
      for (var it in data) {
          // 如果要发送中文 编码
          ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
      }
      return ret.substring(0,ret.length-1)
  }]


    // 2.1.请求拦截的作用
  instance.interceptors.request.use(config => {
    return config
  }, err => {
    console.log(err);
  })
  // 2.2.响应拦截
  instance.interceptors.response.use(res => {
    if(res.data.code != 200){
      return Promise.reject(new Error(res.data.msg || 'Error'))
    }else{
      return res.data
    }
  }, err => {
    console.log(err);
  })
  // 3.发送真正的网络请求
  return instance(config)
}