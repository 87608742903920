import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({ showSpinner: false })
import getPageTitle from '@/utils/get-page-title'
import { scrollTo } from '@/utils/scroll-to'

router.beforeEach((to, from, next) => {
  NProgress.start()
  // set page title
  document.title = getPageTitle(to.meta.title)
  next()
})
router.afterEach(() => {
  // finish progress bar
  NProgress.done()
  scrollTo(0,0)
})