<template>
    <div class="footer">
      <p>主办单位：吉林省科学技术厅 中共吉林省委宣传部 吉林省科学技术协会</p>
      <p>承办单位：吉林省科学技术信息研究所</p>
      <p>技术支持单位：同方知网（北京）技术有限公司吉林省分公司</p>
      <p>吉ICP备07890000号-2</p>
    </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  
};
</script>
<style lang="scss" scoped>
  .footer {
    background: #3a95d7;
    height: 195px;
    padding-top: 20px;
    margin-top: 50px;
 
    p {
      color: #fff;

      padding-top: 10px;
      font-size: 16px;
      text-align: center;
    }
  }
</style>