import { request } from "../utils/request";


// 云展厅列表
export function getCloudList(data) {
  return request({
    url: "/exhibitionHall/getList",
    method: "post",
    data
  });
}
// 云展厅-详情
export function getExhibitionHall(data) {
  return request({
    url: "/exhibitionHall/getExhibitionHall",
    method: "post",
    data
  });
}
// 精彩展项-列表
export function getExhibits(data) {
  return request({
    url: "/wonderfulElements/getList",
    method: "post",
    data
  });
}
// 精彩展项-详情
export function getWonderfulElements(data) {
  return request({
    url: "/wonderfulElements/getWonderfulElements",
    method: "post",
    data
  });
}
// 科普讲堂-列表
export function getLectures(data) {
  return request({
    url: "/popularizationOfScienceClassroom/getList",
    method: "post",
    data
  });
}
// 科普讲堂-详情
export function getLecture(data) {
  return request({
    url: "/popularizationOfScienceClassroom/getPopularizationOfScienceClassroom",
    method: "post",
    data
  });
}
// 新闻中心-列表
export function getNewsList(data) {
  return request({
    url: "/news/getNewsCenterByPage",
    method: "post",
    data
  });
}
// 新闻中心-详情
export function getNewsDetail(data) {
  return request({
    url: "/news/getNewsCenterDetails",
    method: "post",
    data
  });
}
// 新闻中心-轮播
export function newsSlide() {
  return request({
    url: "/news/getNewsCenterHomeTop",
    method: "post"
  });
}
